
import HomePage from '../pages/home.jsx';
import AgregarCategoriaPage from '../pages/agregar-categoria.jsx';
import AgregarProductoPage from '../pages/agregar-producto.jsx';
import AbrirYCompartir from '../pages/abrir-y-compartir.jsx';
import ConfigurarMediosDeContacto from '../pages/configurar-medios-de-contacto.jsx';
import ConfigurarMapaZonaRepartoGoogle from '../pages/configurar-mapa-zona-reparto-google'
import ImportarExportar from '../pages/importar-exportar'
import ConfigurarFacebook from '../pages/configurar-facebook'
import ConfigurarInstagram from '../pages/configurar-instagram'
import ConfigurarGmail from '../pages/configurar-gmail'
import CompartirWhatsapp from '../pages/compartir-whatsapp'
import AgregarOpciones from '../pages/agregar-opciones'
import sumarVisitas from '../pages/sumar-visitas.jsx';
import compartirTiendaCategoriaProducto from '../pages/compartir-tienda-categoria-producto.jsx';
import imagenesPromocionales from '../pages/imagenes-promocionales.jsx';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/agregar-categoria',
    component: AgregarCategoriaPage,
  },
  {
    path: '/agregar-producto',
    component: AgregarProductoPage,
  },
  {
    path: '/abrir-y-compartir',
    component: AbrirYCompartir,
  },
  {
    path: '/configurar-medios-de-contacto',
    component: ConfigurarMediosDeContacto,
  },
  {
    path: '/configurar-mapa-de-reparto-google',
    component: ConfigurarMapaZonaRepartoGoogle,
  },
  {
    path: '/importar-exportar',
    component: ImportarExportar,
  },
  {
    path: '/compartir-whatsapp',
    component: CompartirWhatsapp,
  },
  {
    path: '/configurar-facebook',
    component: ConfigurarFacebook,
  },
  {
    path: '/configurar-instagram',
    component: ConfigurarInstagram,
  },
  {
    path: '/configurar-gmail',
    component: ConfigurarGmail,
  },
  {
    path: '/agregar-opciones',
    component: AgregarOpciones,
  },
  {
    path: '/sumar-visitas',
    component: sumarVisitas,
  },
  {
    path: '/compartir-tienda-categoria-producto',
    component: compartirTiendaCategoriaProducto,
  },
  {
    path: '/imagenes-promocionales',
    component: imagenesPromocionales,
  },
];

export default routes;
