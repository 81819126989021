import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Icon,
  Link
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page className="ndb-page">
    <Navbar backLink>
      <NavTitle>Configurá tu perfil en Instagram</NavTitle>
    </Navbar>
    <Block>
      <p>Podes configurar tu perfil de Instagram para que tus clientes accedan a tu tienda virtual</p>
    </Block>
    <BlockTitle>Pasos</BlockTitle>
    <Block>
      <ol>
        <li><a href="/abrir-y-compartir">Copiá el enlace de tu tienda</a> para tenerlo a mano</li>
        <li>Ingresá a tu perfil de <Link href="https://www.instagram.com/"  external target="_blank">Instagram</Link></li>
        <li>Dirigite a la opción <strong>perfil</strong> y luego <strong>editar perfil</strong></li>
        <li>En el campo <strong>Sitio web</strong>, pegá el enlace de tu tienda, que obtuviste en el paso 1</li>
        <li>Por último presioná el boton <strong>Enviar</strong> ubicado abajo del formulario</li>
      </ol>
    </Block>

  </Page>
);
