import React, { useState, useEffect } from 'react';

import {
  f7,
  f7ready,
  App,
  View,
  List,
  ListItem,
  Page,
  Panel,
  BlockTitle,
} from 'framework7-react';

import routes from '../js/routes';
import store from '../js/store';

const MyApp = () => {
  // Framework7 Parameters
  const f7params = {
    name: 'milocal.app | Ayuda',
    theme: 'md',
    autoDarkTheme: true,
    view: {
      browserHistory: true,
    },
    store: store,
    routes: routes,
  };

  f7ready(() => {
    // Call F7 APIs here
  });

  return (
    <App {...f7params} >
      <Panel left cover swipe swipeOnlyClose>
        <View>
          <Page>
            <img src="static/milocal.jpg" width="100%" alt="milocal.app" />
            <BlockTitle>Contactanos</BlockTitle>
            <List simple-list>
              <ListItem title="Por WhatsApp" href="https://wa.me/541124042245?text=Hola!+Necesito+ayuda+con+mi+negocio." external target="_blank" noChevron></ListItem>
              <ListItem title="Por Email" href="mailto:info@milocal.app?subject=Necesito+ayuda+con+mi+negocio." external target="_blank" noChevron></ListItem>
            </List>
          </Page>
        </View>
      </Panel>

      {/* Your main view, should have "view-main" class */}
      <View main className="safe-areas" url="/" />

    </App>
  );
}
export default MyApp;