import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page name="Actualizá tu catalogo" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Actualizá tu catalogo</NavTitle>
    </Navbar>
    <Block>
      <p>Podes actualizar tu catalogo de manera rapida y sencilla mediante un excel.</p>
    </Block>
    <BlockTitle>Importación - Exportación</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin /></li>
        <li>En la pantalla principal seleccioná <strong>"Catálogo de mi Local"</strong></li>
        <li>Presiona en <strong>Importación / Exportación</strong></li>
      </ol>
    </Block>
    <Block className="text-align-center">
      <img src="https://img.milocal.app/ayuda/importar-exportar.png" width="75%" />
    </Block>
    <BlockTitle>Modo de uso</BlockTitle>
    <Block>
      <ol>
        <li>Presioná en <strong>Descargar excel</strong>. Obtendrás un excel con todos tus productos y categorias</li>
        <li>Modificá el precio, precio sin descuento o descripción de los productos que desees o agrega nuevos productos. (No modifiques la primer linea de encabezado)</li>
        <li>Presioná en <strong>Importar excel</strong> y elegi el archivo que modificaste.<br /></li>
        <li>Listo! Tus productos fueron actualizados con la informacion del excel.</li>
      </ol>
    </Block>
    <BlockTitle>Notas importantes</BlockTitle>
    <Block>
      <ul>
        <li><strong>Un producto es reconocido por su nombre y categoria</strong>. No se puede cambiar el nombre de un producto mediante el excel ya que creara uno nuevo</li>
        <li>Si el nombre de <strong>la categoria no existe</strong> en el catalogo, <strong>se creara una</strong> nueva categoria</li>
        <li>Si el <strong>precio se deja vacio</strong>, es producto se mostrara al cliente como <strong>"Cotizar"</strong></li>
        <li>Asegurate que las <strong>categorias sean escritas de igual manera</strong>, respetando mayusculas, minusculas y acentos</li>
        <li>El excel <strong>solo actualiza o crea nuevos productos o categorias</strong>, no borra productos que no esten en el excel</li>
        <li>Si se desea <strong>borrar un producto debe hacerse de manera manual</strong> en el detalle del producto</li>
        <li>Todos los campos son <strong>obligatorios a menos que digan (opcional)</strong></li>
        <li><strong>Si falla la carga</strong> de un producto por algun error del excel, <strong>toda la operacion será cancelada</strong></li>
        <li>Si al descargar el excel, <strong>el campo descripcion posee caracteres extraños</strong> como <strong>"&amp;nbsp;"</strong> o <strong>"&lt;br /&gt;"</strong> u otros, no los modifiques, ya que estos caracteres son para darle estilos como negrita, subrayado, etc.</li>
      </ul>
    </Block>

  </Page>
);