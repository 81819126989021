import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Icon,
  Link
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page className="ndb-page">
    <Navbar backLink>
      <NavTitle>Configurá tu perfil en Facebook</NavTitle>
    </Navbar>
    <Block>
      <p>Podes configurar tu perfil de Facebook para que tus clientes accedan a tu tienda virtual</p>
    </Block>
    <BlockTitle>Pasos</BlockTitle>
    <Block>
      <ol>
        <li><a href="/abrir-y-compartir">Copiá el enlace de tu tienda</a> para tenerlo a mano</li>
        <li>Ingresá a tu perfil de <Link href="https://facebook.com" external target="_blank">Facebook</Link></li>
        <li>Dirigite a la solapa de <strong>Información</strong></li>
        <li>Dirigite a <strong>Informacion básica y de contacto</strong></li>
        <li>Dentro de la seccion <strong>Sitios web y enlaces sociales</strong></li>
        <li>Hace click en <strong>Agrega un sitio web</strong></li>
        <li>Pega el enlace de tu tienda y por ultimo dale al boton Guardar</li>
      </ol>
    </Block>

  </Page>
);
