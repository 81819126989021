import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  NavTitleLarge,
  ListItem,
  List,
  Block,
  BlockTitle,
  NavLeft,
  Link,
} from 'framework7-react';

const HomePage = () => (
  <Page name="home" className="ndb-page">
    <Navbar className="ndb-navbar">
      <NavLeft>
        <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" textColor="white" />
      </NavLeft>
      <NavTitle>Centro de Ayuda</NavTitle>
    </Navbar>
    <img src="static/centro-de-ayuda.png" width="100%" alt="Centro de Ayuda" />
    <Block className="margin-vertical-half">
      <h2>Bienvenido al Centro de Ayuda</h2>
      <p>Toda la información para crear tu negocio online, compartirlo y aprovechar milocal.app al máximo</p>
    </Block>
    <BlockTitle>Promocionar tu tienda</BlockTitle>
    <List>
      <ListItem title="Consejos para sumar visitar" link="/sumar-visitas"></ListItem>
      <ListItem title="Compartí tu tienda por WhatsApp" link="/compartir-whatsapp"></ListItem>
      <ListItem title="Configurá tu perfil en Facebook" link="/configurar-facebook"></ListItem>
      <ListItem title="Configurá tu perfil en Instagram" link="/configurar-instagram"></ListItem>
      <ListItem title="Configurá tu firma en GMail" link="/configurar-gmail"></ListItem>
      <ListItem title="Compartí tu tienda, categoría y producto" link="/compartir-tienda-categoria-producto"></ListItem>
      <ListItem title="Obtener enlaces y codigo QR para compartir" link="/abrir-y-compartir"></ListItem>
      <ListItem title="Compartí imágenes promocionales de tu tienda" link="/imagenes-promocionales"></ListItem>
    </List>
    <BlockTitle>Administracion de tu catalogo</BlockTitle>
    <List>
      <ListItem title="Cargá o actualizá tu catálogo con un excel" link="/importar-exportar"></ListItem>
      <ListItem title="Agregá categorías para tus productos" link="/agregar-categoria"></ListItem>
      <ListItem title="Agregá productos a tu tienda" link="/agregar-producto"></ListItem>
      <ListItem title="Agregá extras y adicionales a tus productos" link="/agregar-opciones"></ListItem>
    </List>
    <BlockTitle>Extras</BlockTitle>
    <List>
      <ListItem title="Configurá tus medios de contacto" link="/configurar-medios-de-contacto"></ListItem>
      <ListItem title="Configura el mapa con tu zona de reparto" link="/configurar-mapa-de-reparto-google"></ListItem>
    </List>

    <BlockTitle>¿Necesitás más ayuda?</BlockTitle>
    <List mediaList>
      <ListItem title="Escribinos por WhatsApp" subtitle="(+549) 11 2404 2245" link="https://wa.me/541124042245?text=Hola!+Necesito+ayuda+con+mi+negocio." external target="_blank"></ListItem>
      <ListItem title="Escribinos por Email" subtitle="info@milocal.app" link="mailto:info@milocal.app?subject=Necesito+ayuda+con+mi+negocio." external></ListItem>
    </List>

  </Page>
);
export default HomePage;