import React from 'react';
import { Link } from 'framework7-react';

/**
 * Muestra un link al panel de administración
 */
export default function LinkPanelAdmin(props) {
    return (
        <Link href="https://admin.milocal.app" external target="_blank">Panel de Administración</Link>
    );
}
