import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page name="Compartir aplicacion" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Enlace y código QR</NavTitle>
    </Navbar>
    <Block>
      <p>Tu aplicación tiene una dirección web exclusiva para compatir con tus clientes. También podés descargar e imprimir un código QR para ubicar en la puerta de tu local,
        tarjetas personales, folletería y más. </p>
    </Block>
    <BlockTitle>Abrir y copiar tu enlace web</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin/></li>
        <li>En la pantalla principal seleccioná <strong>"Mi Tienda Online"</strong></li>
        <li>En la sección <strong>enlace de mi tienda</strong> visualizás el enlace para compartir a tus clientes</li>
        <li>Presioná el botón <strong>"Copiar"</strong> para copiar al portapapeles la dirección de tu aplicación. La podés pegar en WhatsApp, Instagram, un mail o donde quieras.</li>
      </ol>
    </Block>

    <Video src="https://img.milocal.app/ayuda/abrir-y-compartir.mp4" />

    <BlockTitle>Descargar tu código QR</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin/></li>
        <li>En la pantalla principal seleccioná <strong>"Mi Tienda Online"</strong></li>
        <li>Seleccioná <strong>"Código QR"</strong></li>
        <li>Presioná el botón <strong>"Descargar"</strong> para descargar una imagen con el código QR.</li>
      </ol>
    </Block>

  </Page>
);