import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle
} from 'framework7-react';

export default () => (
  <Page name="Compartir aplicacion" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Compartir WhatsApp</NavTitle>
    </Navbar>
    <Block>
      <p>Compartí tu tienda a todos tus grupos y contactos de WhatsApp.</p>
    </Block>
    <BlockTitle>Enviá mensaje a tus contactos y grupos</BlockTitle>
    <Block>
      <ol>
        <li><a href="/abrir-y-compartir">Copiá el enlace de tu tienda</a> para tenerlo a mano</li>
        <li>Creá un texto breve para compartir tu tienda. Por ejemplo:<br />
          <div className="margin-vertical-half" style={{ fontFamily: "monospace" }}>
            ¡Hola! Quiero invitarlos a visitar mi nueva tienda online, donde van a encontrar ofertas exclusivas de lanzamiento. Los espero en PEGAR_ENLACE_DE_LA_TIENDA
          </div>
        </li>
        <li>Enviá ese mensaje por WhatsApp a tus grupos, clientes y amigos</li>
      </ol>
    </Block>

    <BlockTitle>Responder invitando a visitar tu tienda</BlockTitle>
    <Block>
      <ol>
        <li>Terminá las conversaciones de WhatsApp invitando a visitar tu tienda. Por ejemplo:<br />
          <div className="margin-vertical-half" style={{ fontFamily: "monospace" }}>
            ¡Muchas gracias! Para una mejor atención, te espero la próxima en PEGAR_ENLACE_DE_LA_TIENDA
          </div>
        </li>
      </ol>
    </Block>

  </Page>
);