import { Block } from 'framework7-react';
import React from 'react';

/**
 * Bloque con un video de type "video/mp4", indicado en props.src
 */
export default function Video(props) {
    return (
        <Block className="text-align-center">
            <video className="ndb-video"  controls autoPlay muted>
                <source src={props.src} type="video/mp4" />
            </video>
        </Block>
    );
}
