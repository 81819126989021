import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Link
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

const AgregarOpcionesPage = () => (
  <Page name="Agregar categoria" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Agregar extras y adicionales a tus productos</NavTitle>
    </Navbar>
    <Block>
      <p>Los extras y adicionales son opciones que tus clientes pueden seleccionar al producto que están comprando</p>
      <p>Por ejemplo pueden seleccionar las variantes de colores, adicionales de salsas, gustos distintos etc.</p>
    </Block>
    <BlockTitle>Pasos</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin /></li>
        <li>En la pantalla principal seleccioná <strong>"Catálogo de mi Local"</strong></li>
        <li>Seleccioná <strong>"Extras y adicionales"</strong></li>
        <li>Presioná el botón <strong>"Agregar"</strong></li>
        <li><strong>Completá el nombre </strong> de la opción por ejemplo color</li>
        <li>Presioná en <strong>"+AGREGAR ITEM" </strong> y escribí la primero opción, por ejemplo rojo</li>
        <li><strong>Repetí el paso anterior</strong> hasta agregar todas las opciones que puede tener el producto</li>
        <li>Una vez creada la opción vamos a continuar <strong>configurando el producto que tendrá estas opciones</strong>. Para esto nos dirigimos a la edición del producto en cuestión</li>
        <li>Dentro del producto, tocamos los 3 puntitos ubicados arriba a la derecha y dentro presionamos <strong>Gestionar adicionales</strong>. Nos saldrá todas las opciones disponibles que hemos creados</li>
        <li>Aquí <strong>seleccionamos la cantidad máxima y mínima</strong> que debe seleccionar el cliente para este producto. Si el mínimo es distinto a 0 significa que el cliente esta obligado a seleccionar una opción. Si el mínimo es 0 significa que puede o no seleccionar una opción</li>
        <li>Un producto <strong>puede tener muchas opciones distintas</strong> que puede seleccionar el cliente</li>
        <li>Una vez configurado le damos a <strong>guardar</strong>. Si volvemos a la edición del producto, abajo de todo notaremos una sección llamada "Opciones" con las opciones que posee ese producto</li>
      </ol>
    </Block>

    <Video src="https://img.milocal.app/ayuda/configuracion-opciones.mp4" />

    <BlockTitle>Aclaraciones</BlockTitle>
    <Block>
      <p>Por el momento, los adicionales no poseen un costo, es decir no modificar el precio del producto (si es que tiene precio)</p>
    </Block>

  </Page>
);
export default AgregarOpcionesPage;