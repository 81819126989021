import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Link
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page name="Sumar visitas" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Como sumar visitas</NavTitle>
    </Navbar>
    <BlockTitle>Configurar tus redes sociales para vincular tu tienda online</BlockTitle>
    <Block>
      <p>En esta nueva era digital, las redes sociales son una herramienta indispensable para que llegar a muchas mas personas. Es por esto que recomendamos <strong>asociar tu tienda online en tus perfiles</strong> de Facebook, Instagram y WhatsApp, de esta manera tus clientes pueden acceder de manera rápida a tus productos actualizados</p>
      <p><Link href="/configurar-facebook">Configurá tu perfil de Facebook</Link></p>
      <p><Link href="/configurar-instagram">Configurá tu perfil en Instagram</Link></p>
    </Block>
    <BlockTitle>Compartir tu tienda en redes sociales</BlockTitle>
    <Block>
      <p>Para sumar mas clientes es necesario que <strong>compartas tus productos en las redes sociales</strong> y de esta manera nuevas personas conozcan que tenes para ofrecerles.</p>
      <p>Recomendamos <strong>compartir al menos 3 veces por semana</strong> algo de tu tienda en las redes sociales para generar un reconocimiento de tu marca.</p>
      <p>Para esto milocal.app cuenta con varias <strong>herramientas para poder compartir tu tienda</strong>, categoría y productos de manera fácil. Ademas te brindamos imágenes para poder promocionarte en las redes sociales</p>
      <p><Link href="/compartir-tienda-categoria-producto">Compartí tu tienda, categoría o producto</Link></p>
      <p><Link href="/imagenes-promocionales">Utilizá las imágenes promocionales</Link></p>
    </Block>



  </Page>
);