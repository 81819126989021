import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Icon,
  Link
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page name="Configurar medios de contacto" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Medios de contacto</NavTitle>
    </Navbar>
    <Block>
      <p>Los medios de contacto, como Facebook, Instagram, WhatsApp o el teléfono es utíl para que tus clientes puedan realizarte consultas.</p>
      <p>Estos campos son opcionales y sólo se mostrarán a tus clientes aquellos que estén completos.</p>
    </Block>
    <BlockTitle>Pasos</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin /></li>
        <li>En la pantalla principal seleccioná <strong>"Personalización"</strong></li>
        <li>Completá la <strong>dirección</strong> física de tu local</li>
        <li>Completá el <strong>teléfono</strong> donde tus clientes te puedan contactar</li>
        <li>Completá el <strong>WhatsApp</strong> comenzando con el código de area y numero. Por ejemplo: Buenos Aires 1124042245.
          Para comprobar si está correcto, tocá en el ícono <Icon material="open_in_new" color="blue" /> te debería abrir WhatsApp con un mensaje para ese teléfono</li>
        <li>Completá el <strong>Facebook</strong> de tu local con el nombre de usuario. Por ejemplo, si el facebook es "https://www.facebook.com/milocalapp" soló completar con "milocalapp"</li>
        <li>Completá el <strong>Instagram</strong> de tu local con el nombre de usuario. Por ejemplo si el instagram es "https://www.instagram.com/milocalapp" soló completar con "milocalapp"</li>
        <li>Presioná el botón <strong>"GUARDAR"</strong></li>
      </ol>
    </Block>

    <Video src="https://img.milocal.app/ayuda/configurar-medios-de-contacto.mp4" />

    <BlockTitle>Descripción de campos</BlockTitle>
    <Block>
      <ul>
        <li><strong>Nombre:</strong> nombre de la empresa que aparecerá en la pantalla principal de tu aplicación.</li>
        <li><strong>Información de delivery (sólo con Plan Profesional):</strong> se utiliza para informar a tus clientes que medios, costos, alcance, horarios y cualquier
          información valiosa cuando te realizan una compra.</li>
        <li><strong>Dirección:</strong> dirección física de tu local (si posee una)</li>
        <li><strong>Mensaje de bienvenida:</strong> mensaje para tus clientes cuando ingresan en tu aplicación.
          Útil para contar un poco sobre tu oferta, quien sos, horarios de atención, etc.
          Se visualiza abajo en la pagina principal de tu aplicación.</li>
        <li><strong>Teléfono:</strong> teléfono de contacto para tus clientes.</li>
        <li><strong>WhatsApp:</strong> WhatsApp de contacto para tus clientes. Se completa con el código de area y número. Por ejemplo: Buenos Aires 1124042245.</li>
        <li><strong>Facebook:</strong> Facebook de contacto para tus clientes. Se completa con el nombre de usuario. Por ejemplo, si el facebook es "https://www.facebook.com/milocalapp" soló completar con "milocalapp"</li>
        <li><strong>Instagram:</strong> Instagram de contacto para tus clientes. Se completa con el nombre de usuario. Por ejemplo si el instagram es "https://www.instagram.com/milocalapp" soló completar con "milocalapp"</li>
        <li><strong>Color principal del catálogo:</strong> color personalizado que tendrá tu aplicación.
          Recomendamos no utilizar colores claros para facilitar la legibilidad de textos a tus clientes.</li>
        <li><strong>Modo de visualización del catalogo:</strong> Forma en la que tus clientes visualizaran tus productos en la pantalla principal.
          <ul>
            <li><strong>PRODUCTOS:</strong> Se verán todos los productos ordenados por categoría</li>
            <li><strong>CATEGORIAS:</strong> Se verán solo las categorías</li>
            <li><strong>CATEGORIA_PRODUCTOS:</strong> Se verán primero todos los productos y al final las categorías</li>
          </ul>
        </li>
        <li><strong>Imagen de Portada:</strong> imagen banner que aparecerá en la pantalla principal de tu aplicación.
          Recomendamos usar imágenes atractivas y de buena calidad. Te pasamos algunas páginas para encontrar imágenes que te pueden ser de ayuda.
          <ul>
            <li><Link external target="_blank" href="https://www.pexels.com/">https://pexels.com</Link></li>
            <li><Link external target="_blank" href="https://unsplash.com/">https://unsplash.com</Link></li>
          </ul>
        </li>
      </ul>
    </Block>

  </Page>
);
