import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
} from 'framework7-react';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page className="ndb-page">
    <Navbar backLink>
      <NavTitle>Imágenes promocionales</NavTitle>
    </Navbar>
    <Block>
      <p>Milocal.app provee imágenes para que promociones tu tienda en las redes sociales</p>
    </Block>
    <BlockTitle>Compartir imágenes</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin /></li>
        <li>En la pantalla principal seleccioná <strong>"Mi Local Online"</strong></li>
        <li>En la sección <strong>"Imágenes para promocionar en redes sociales"</strong> vas a encontrar todas las imágenes para que promociones tu tienda.</li>
        <li>Presiona en la imagen que querés, mirá la vista previa y <strong>descarga la imagen para postear</strong> en redes sociales.</li>
        <li>Algunas imágenes vienen con textos configurables para tu tienda. Para esto solo <strong>escribí el texto1, 2, etc</strong> y previsualiza como queda la imagen. Luego dale a descargar y listo.</li>
      </ol>
    </Block>
  </Page>
);
