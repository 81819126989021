import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Link
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

const AgregarProductoPage = () => (
  <Page name="Agregar categoria" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Agregar un producto</NavTitle>
    </Navbar>
    <Block>
      <p>Usa los productos para que tus clientes conozcan y adquieran lo que ofreces con tu negocio.</p>
    </Block>
    <BlockTitle>Pasos</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin/></li>
        <li>En la pantalla principal seleccioná <strong>"Catálogo de mi Local"</strong></li>
        <li>Seleccioná <strong>"Productos"</strong></li>
        <li>Presioná el botón <strong>"CREAR NUEVO"</strong></li>
        <li><strong>Completá el nombre</strong> del producto</li>
        <li><strong>Completá el precio de venta</strong> del producto</li>
        <li><strong>Selecciona la categoría</strong> a la que pertenece el producto (si la categoría no existe, debes crearla primero <Link href="/agregar-categoria">siguiendo estos pasos</Link>).</li>
        <li>Opcionalmente podes completar una <strong>descripción detallada</strong> del producto, que tus clientes podrán ver en el detalle del mismo.</li>
        <li>Presioná el botón <strong>"GUARDAR"</strong></li>
      </ol>
    </Block>

    <Video src="https://img.milocal.app/ayuda/agregar-producto.mp4" />

    <BlockTitle>Descripción de campos</BlockTitle>
    <Block>
      <ul>
        <li><strong>Nombre:</strong> nombre del producto</li>
        <li><strong>Precio:</strong> precio de venta del producto</li>
        <li><strong>Categoría:</strong> categoría que alojara al producto</li>
        <li><strong>Descripción detallada:</strong> descripción del producto que se muestra en el detalle del mismo. Util para destacar cualidades, cantidades y cualquier información para tentar al cliente a comprar</li>
        <li><strong>Publicado:</strong> si esta tildado el producto sera visible en tu aplicación. Util para quitar un producto de stock temporalmente.</li>
        <li><strong>Precio sin descuento:</strong> precio que se mostrara tachado para mostrar una rebaja en el producto. Util para tentar a los clientes con productos en oferta.</li>
        <li><strong>Destacado:</strong> si esta tildado el producto aparecerá en la pantalla principal de tu aplicación. Los productos destacados serán lo primero que ven tus clientes al entrar en la aplicación (recomendamos no agregar mas de 5 productos como destacados)</li>
      </ul>
    </Block>

  </Page>
);
export default AgregarProductoPage;