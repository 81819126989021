import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Link
} from 'framework7-react';

export default () => (
  <Page name="Configurar medios de contacto" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Configurá tu firma en GMail</NavTitle>
    </Navbar>
    <Block>
      <p>Podes configurar la firma de los emails que enviás usando GMail para que tus clientes accedan a tu tienda online. Tu firma en los mails aparece en cada mail que enviés, abajo de todo.</p>
    </Block>
    <BlockTitle>Pasos</BlockTitle>
    <Block>
      <ol>
        <li><a href="/abrir-y-compartir">Copiá el enlace de tu tienda</a> para tenerlo a mano</li>
        <li>Ingresá a tu cuenta de <Link external target="_blank" href="https://mail.google.com">GMail</Link></li>
        <li>Tocá el <strong>engranaje</strong> en la parte superior derecha y seleccioná <strong>Ver toda la configuración</strong></li>
        <li>Desplazate hacia abajo hasta la <strong>sección Firma</strong></li>
        <li>Escribí tu nombre, datos de contacto y <strong> pegá el enlace de tu tienda (que obtuviste en el paso 1)</strong></li>
        <li>Desplazate hacia abajo de todo y <strong>tocá el botón Guardar Cambios</strong></li>
      </ol>
    </Block>

  </Page>
);
