import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

const AgregarCategoriaPage = () => (
  <Page name="Agregar categoria" className="ndb-page">
    <Navbar backLink>
      <NavTitle>Agregar una categoría</NavTitle>
    </Navbar>
    <Block>
      <p>Las categorías te permiten organizar tus productos para que tus clientes puedan encontrar más fácilmente lo que buscan.</p>
      <p>También podés crear categorías para promocionar ciertos productos.</p>
    </Block>
    <BlockTitle>Pasos</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin/></li>
        <li>En la pantalla principal seleccioná <strong>"Catálogo de mi Local"</strong></li>
        <li>Seleccioná <strong>"Categorias"</strong></li>
        <li>Presioná el boton <strong>"CREAR NUEVA"</strong></li>
        <li><strong>Completá el nombre</strong> de la categoría (opcionalmente podés ingresar una descripción corta que se mostrará al ser listada en tu aplicación)</li>
        <li>Presioná el boton <strong>"GUARDAR"</strong></li>
      </ol>
    </Block>

    <Video src="https://img.milocal.app/ayuda/agregar-categoria.mp4" />

    <BlockTitle>Descripción de campos</BlockTitle>
    <Block>
      <ul>
        <li><strong>Nombre:</strong> nombre de la categoría que contendrá los productos</li>
        <li><strong>Descripción:</strong> descripción de la categoría que se mostrará en el listado de la pantalla principal de tu aplicación.</li>
        <li><strong>Sólo para uso interno:</strong> Si está tildado, esta categoría y todos los productos que contenga no seran visibles para tus clientes.
          Si tenés el Plan Profesional, podés crear categorías de uso interno para productos que se vendan por distintos canales (ej: Pedidos Ya, Instagram, desde tu local físico, etc).
          También puede ser útil para poder crear una categoría con sus productos y hacerlo público una vez se tenga todo configurado.</li>
        <li><strong>Imagen:</strong> imagen de la categoría que se visualiza en el listado de la pantalla principal.</li>
      </ul>
    </Block>

  </Page>
);
export default AgregarCategoriaPage;