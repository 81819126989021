import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Icon,
  Link
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page name="Configurar mapa de zona de reparto" className="ndb-page">

  <Navbar backLink>
        <NavTitle>Configurar mapa zona de reparto</NavTitle>
      </Navbar>
      <Block>
        <p>El mapa de reparto sirve para que tus clientes visualicen un mapa con las zonas disponibles que tenes para realizar delivery.</p>
        <p>Si el mapa no esta configurado no se vera en tu catalogo.</p>
      </Block>
      <BlockTitle>Pasos</BlockTitle>
      <Block>
        <ol>
          <li>Ingresá al <LinkPanelAdmin /></li>
          <li>En la pantalla principal seleccioná <strong>"Envíos"</strong></li>
          <li>Presiona en <strong>Agregar Mapa de Google</strong></li>
          <li>Ingresá con tu cuenta de google a <Link external href="https://mymaps.google.com/" target="_blank">google mymaps</Link> mediante el link proporcionado</li>
          <li>Presiona el botón de <strong>EMPEZAR</strong></li>
          <li>Presiona <strong>+ crear un nuevo mapa</strong></li>
          <li>Hace click en el icono <Icon material="room" color="blue" /><strong>añadir marcador</strong> y ubicalo en tu negocio</li>
          <li>Escribí el nombre de tu negocio y dale color y estilo presionando en <Icon material="format_color_fill" color="blue" /></li>
          <li>Hace click en el icono <Icon material="timeline" color="blue" /><strong>dibujar linea</strong> y traza el contorno del mapa delimitando tu zona de reparto</li>
          <li>Escribí la descripción de la zona marcada, por ejemplo si es gratuito, pago o cualquier otra información que consideres importante que sepa tu cliente a la hora de realizar una compra</li>
          <li>Realizá el paso anterior con todas las zonas que quieras marcar</li>
          <li>Tocá en <Icon material="person_add" color="blue" /><strong>compartir</strong></li>
          <li>Ponele un titulo por ejemplo, "Zona de reparto" luego ok</li>
          <li>Tilda "Habilitar la función de compartir mediante enlace"</li>
          <li>Tilda "Público: cualquier usuario de Internet puede encontrar tu contenido y acceder a él"</li>
          <li>Y luego click en <Icon material="content_copy" color="blue" /><strong>copiar</strong></li>
          <li>Volve a la aplicación de admin y pega la dirección copiada en el paso anterior y dale a <strong>guardar mapa</strong></li>
        </ol>
        <p>Si todo salio correcto se debe comenzar a visualizar el mapa en la misma pantalla de configuración (como se muestra en el video) y en tu catalogo.</p>
      </Block>

      <Video src="https://img.milocal.app/ayuda/configurar-mapa-zona-reparto-google.mp4" />
    </Page>
);
