import React from 'react';
import {
  Page,
  Navbar,
  NavTitle,
  Block,
  BlockTitle,
  Icon,
  Link
} from 'framework7-react';
import Video from '../components/video';
import LinkPanelAdmin from '../components/LinkPanelAdmin';

export default () => (
  <Page className="ndb-page">
    <Navbar backLink>
      <NavTitle>Compartir tu tienda</NavTitle>
    </Navbar>
    <Block>
      <p>Milocal.app provee una serie de herramienta para que te sea fácil compartir por distintos medios.</p>
    </Block>
    <BlockTitle>Compartir tu tienda</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin /></li>
        <li>En la pantalla principal seleccioná <strong>"Mi Local Online"</strong></li>
        <li>En la sección <strong>"Compartir en..."</strong> tenes los botones necesarios para:
          <ul>
            <li><strong>Facebook:</strong> Compartir tu tienda en facebook. Este link viene con una vista previa que facebook se encarga de mostrar</li>
            <li><strong>WhatsApp:</strong> Compartir tu tienda por WhatsApp a algún contacto (al pegar esperar unos segundo antes de enviar, ya que WhatsApp busca y carga una vista previa para ser enviado con el link, esto no funciona para WhatsApp Bussiness)</li>
            <li><strong>Copiar enlace:</strong> Copia el enlace de tu tienda para poder pegar y enviar por algún otro medio</li>
          </ul>
        </li>
      </ol>
    </Block>
    <BlockTitle>Compartir una categoría</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin /></li>
        <li>En la pantalla principal seleccioná <strong>"Catálogo de mi local"</strong></li>
        <li>Seleccioná <strong>"Categoría"</strong> e ingresa a la categoría que querés compartir</li>
        <li>Una vez dentro, arriba a la izquierda encontraras el <strong>icono de compartir</strong> <Icon material="share" color="blue" />, al presionar se visualizan todas las opciones para compartir la categoría</li>
      </ol>
    </Block>
    <BlockTitle>Compartir un producto</BlockTitle>
    <Block>
      <ol>
        <li>Ingresá al <LinkPanelAdmin /></li>
        <li>En la pantalla principal seleccioná <strong>"Catálogo de mi local"</strong></li>
        <li>Seleccioná <strong>"Productos"</strong> e ingresa al producto que querés compartir</li>
        <li>Una vez dentro, arriba a la izquierda encontraras el <strong>icono de compartir</strong> <Icon material="share" color="blue" />, al presionar se visualizan todas las opciones para compartir el producto</li>
      </ol>
    </Block>
    <BlockTitle>Notas</BlockTitle>
    <Block>
      <ol>
        <li>Solo se pueden compartir los productos y categorías que estén publicados y visibles para tu clientes</li>
        <li>Al pegar un link, siempre esperar unos segundos antes de enviar ya que algunos programas como WhatsApp cargan una vista previa del link, lo que ayuda a hacerlo mas llamativo</li>
      </ol>
    </Block>
  </Page>
);
